import axios from "../../utils/request";

//异常管理
export function get_breakdown_reason_list_page(data) {
  return axios({
    url: "/clodchainAM/web/get_breakdown_reason_list_page",
    method: "get",
    params: data
  });
}
//新增
export function save_breakdown_reason_info(data) {
  return axios({
    url: "/clodchainAM/web/save_breakdown_reason_info",
    method: "post",
    data: data
  });
}
//删除
export function delete_breakdown_reason(data) {
  return axios({
    url: "/clodchainAM/web/delete_breakdown_reason",
    method: "post",
    data: data
  });
}
